import type { ThemeProviderProps } from "next-themes/dist/types";

export const getPageTheme = ({
  pathname,
}: {
  pathname: string;
}): ThemeProviderProps["forcedTheme"] => {
  const darkModePages: string[] = [];
  const lightModePages = ["/login"];
  if (darkModePages.some((name) => pathname === name) && lightModePages.some((name) => pathname === name)) {
    return;
  }
  if (darkModePages.some((name) => pathname === name)) return "dark";
  if (lightModePages.some((name) => pathname === name)) return "light";

  if (
    darkModePages.some((name) => pathname.startsWith(name)) &&
    lightModePages.some((name) => pathname.startsWith(name))
  ) {
    return;
  }
  if (darkModePages.some((name) => pathname.startsWith(name))) return "dark";
  if (lightModePages.some((name) => pathname.startsWith(name))) return "light";

  return;
};
