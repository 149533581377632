import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import stylesheet from "~/tailwind.css?url";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { getPageTheme } from "./utils/theme";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: stylesheet }];

export const meta: MetaFunction = () => {
  return [{ title: "Cytometa" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID ?? "";
  const clarityTagId = import.meta.env.VITE_CLARITY_TAG_ID ?? "";

  return json({
    gaTrackingId,
    clarityTagId,
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { gaTrackingId, clarityTagId } = useLoaderData<typeof loader>();
  return (
    <html lang="ja" suppressHydrationWarning>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Links />
        <Meta />
      </head>
      <body>
        {clarityTagId ? (
          <script
            async
            // biome-ignore lint/security/noDangerouslySetInnerHtml: To Add Microsoft Clarity
            dangerouslySetInnerHTML={{
              __html: `
              (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "${clarityTagId}");
          `,
            }}
          />
        ) : null}
        {gaTrackingId ? (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
            <script
              // biome-ignore lint/security/noDangerouslySetInnerHtml: Google Analytics
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', "${gaTrackingId}", {
                page_path: window.location.pathname,
                });
            `,
              }}
            />
          </>
        ) : null}
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { pathname } = useLocation();

  return (
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="light" forcedTheme={getPageTheme({ pathname })}>
        <Outlet />
      </NextThemesProvider>
    </NextUIProvider>
  );
}
